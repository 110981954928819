import config from "./config";

export function determineServiceStatus(statusResponse) {
  if (Array.isArray(statusResponse) && statusResponse.length > 0) {
    for (let item of statusResponse) {
      if (item.desired_count === item.running_count && item.running_count === 0) {
        return "Resting";
      } else if (item.desired_count === item.running_count) {
        return "Running";
      } else if (item.desired_count > 0) {
        return "Starting";
      } else {
        return "Stopping";
      }
    }
  } else {
    // Handling for non-array statusResponse
    if (
      statusResponse.desired_count === statusResponse.running_count &&
      statusResponse.running_count === 0
    ) {
      return "Resting";
    } else if (statusResponse.desired_count === statusResponse.running_count) {
      return "Running";
    } else if (
      statusResponse.desired_count > statusResponse.running_count &&
      statusResponse.running_count > 0
    ) {
      return "Scaling-up";
    } else if (
      statusResponse.desired_count < statusResponse.running_count &&
      statusResponse.desired_count > 0
    ) {
      return "Scaling-down";
    } else if (statusResponse.desired_count > 0) {
      return "Starting";
    } else {
      return "Stopping";
    }
  }
}

function getBaseUrl() {
  return config.API_BASE_URL;
}

export async function getServiceStatus(serviceName) {
  // Get the base URL from the environment variable
  const baseUrl = getBaseUrl();

  if (!baseUrl) {
    throw new Error("API base URL is not defined in environment variables");
  }

  // Construct the API endpoint
  const apiEndpoint = `${baseUrl}/${serviceName}/status`;
  console.log(apiEndpoint);
  try {
    const response = await fetch(apiEndpoint);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching service status:", error);
    throw error;
  }
}

export async function startService(serviceName) {
  // Get the base URL from the environment variable
  const baseUrl = getBaseUrl();

  if (!baseUrl) {
    throw new Error("API base URL is not defined in environment variables");
  }

  // Construct the API endpoint
  const apiEndpoint = `${baseUrl}/${serviceName}/start`;
  console.log(apiEndpoint);
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching service status:", error);
    throw error;
  }
}

export async function stopService(serviceName) {
  // Get the base URL from the environment variable
  const baseUrl = getBaseUrl();

  if (!baseUrl) {
    throw new Error("API base URL is not defined in environment variables");
  }

  // Construct the API endpoint
  const apiEndpoint = `${baseUrl}/${serviceName}/stop`;
  console.log(apiEndpoint);
  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching service status:", error);
    throw error;
  }
}
