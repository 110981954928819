// demoData.js
export const demoData = [
  {
    key: "AI_Health_Assessor",
    title: "AI Health Assessor",
    description:
      "Using a voice based AI assistant, understand your customer background and needs better so you can provide customised solutions. With best in class natural language understanding, you can avoid frustrating long surveys and go directly to the root of the problem",
    url: "",
    video:
      "https://netorgft10938452.sharepoint.com/:v:/s/NearTekPodAll/EcIwObTCy-RAhMtZhTFwy2MBcK6tTlFdrkqNvERVCpeqKw?e=gzuMuL",
  },
  {
    key: "Hiring_App",
    title: "Hiring App",
    description:
      "Use AI to simplify your hiring process. From processing the resumes you received from potential candidates and assessing their fitness for the role, This tool brings make the hiring process smooth for all involved.",
    url: "https://lcp.neartekpod.io/",
    video:
      "https://netorgft10938452.sharepoint.com/:v:/s/NearTekPodAll/EWo2E-bIDexJgSsO_3FdlS0Bxz3kJBGbRBN5QOK-YMxIGA?e=hOwHk9",
  },
  {
    key: "Voice_Shoping",
    title: "Voice Shoping",
    description:
      "Voice shopping is growing exponentially. Be in the lead of this revolution, by leveraging the almost human like voice assistant to make your customers' buying experience simpler.",
    url: "https://lcp.neartekpod.io/",
    video:
      "https://netorgft10938452.sharepoint.com/:v:/s/NearTekPodAll/EQMJTinw0M9Kl-K-9F-r-lgB9gpghYvawe1OtvNcSRRPiw?e=zfmWDm",
  },
  // Add more data as needed
];
