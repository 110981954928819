import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <header className="header">
        <div className="logo">
          {" "}
          <img
            src="https://www.neartekpod.com/hubfs/raw_assets/public/ntp-2.0-main/assets/logo.png"
            className="w-32 p-2  md:w-[196px]"
            alt="Neartekpod Logo"
          />
        </div>
        <div className="user-info">
          {isAuthenticated ? (
            <>
              <span className="text-xs md:text-xl"> Welcome {user.name} </span>

              <button
                className="logout-btn bg-logout p-2 text-xs md:text-xl"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <button
                className="logout-btn"
                onClick={() => loginWithRedirect()}
              >
                Login
              </button>
            </>
          )}
        </div>
        {/* <div className="text-center">
          <h1 className="text-2xl md:text-4xl font-montserrat font-bold text-gray-800 mt-5 md:mt-0">
            Welcome to <span className="text-Near">Near</span>
            <span className="text-tek">tek</span>
            <span className="text-customBlue">pod</span>
          </h1>
          <p className="text-gray-700 text-center font-montserrat">
            Your Partner in Transforming Technology into Success
          </p>
        </div> */}
    </header>
  );
}

export default Header;
